import React, { Suspense } from "react";

import { hydrateRoot, createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";

import { App } from "./App";
import { setupStore } from "./redux/store";

import "./index.scss";
import { ApiService } from "services/ApiService";
import ErrorBoundary from "./components/common/ErrorBoundary";
import {getAppRoutes} from "./Router/getAppRoutes";
import {withSSR} from "react-i18next";
import {getCookie} from "./utils/helpers/getCookie";
import {LOCALS} from "./utils/constants/locals";
import i18next from "i18next"
import {HelmetProvider} from "react-helmet-async";

const rootEl = document.getElementById("root");
rootEl.style.opacity = 1;

if (process.env.NODE_ENV === "production") {
  // console.log = () => {};
}

const defaultLang = "en";
const locales = i18next.languages || Object.values(LOCALS);

const pathnameLang = locales.includes(window.location.pathname.slice(1, 3))
  ? window.location.pathname.slice(1, 3)
  : null;

const initLang = pathnameLang || getCookie("lang") || i18next?.language || defaultLang;

const routes = createRoutesFromElements(
  getAppRoutes(initLang)
);

const ExtendedApp = withSSR()(App);

const optionRender = (store = {}) => {
  const app = (
    <ErrorBoundary>
      <Provider store={setupStore(store)}>
        <HelmetProvider>
          <Suspense fallback={null}>
            <ExtendedApp
              locales={locales}
              lang={initLang}
              initialLanguage={initLang}
              routerProvider={RouterProvider}
              routerProps={{
                router: createBrowserRouter(routes)
              }}
            />
          </Suspense>
        </HelmetProvider>
      </Provider>
    </ErrorBoundary>
  )
  
  if (rootEl.hasChildNodes()) {
    return hydrateRoot(rootEl, app, { identifierPrefix: "react-app-ipv4" });
  } else {
    const root = createRoot(rootEl);
    
    return root.render(app);
  }
  
};

const initStore = async () => {
  try {
    const contentId = document.querySelector(
      `meta[name="ssr-content-id"]`
    ).content;
    if (contentId) {
      const req = await ApiService.cacheSSR(contentId);
      if (req.data.state) {
        return optionRender(req.data.state);
      }
    }
    return optionRender();
  } catch (e) {
    return optionRender();
  }
};

initStore();


