export const loadZohoChat = (lang) => {
  const code = process.env.REACT_APP_ZOHO_SALESIQ_CODE;
  const chatId = "zsiqscript";
  const CHAT_WAIT_TIME_MINUTES = 20;
  const SECONDS_PER_MINUTE = 60;
  const waitTime = CHAT_WAIT_TIME_MINUTES * SECONDS_PER_MINUTE;
  
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    ready: () => {
      window.$zoho.salesiq?.chat?.waittime?.(waitTime);
      window.$zoho.salesiq.language(lang);
      window.$zoho.salesiq?.customize({
        operator: {
          name: 'Customer Support',
        },
      });
    },
  };
  
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = chatId;
  script.src = `https://salesiq.zohopublic.eu/widget?wc=${code}`;
  script.defer = true;
  
  // const firstScriptEl = document.getElementsByTagName("script")[0];
  // const firstScriptEl = document.getElementsByTagName("noscript")[0];
  // console.log(firstScriptEl);
  // firstScriptEl.parentNode.insertBefore(script, firstScriptEl);
  
  document.body.appendChild(script);
};
