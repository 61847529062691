import "./CustomInput.scss";
import { useRef, useState } from "react";
import { classNames } from "../../../utils/helpers/classNames";

export const CustomInput = ({ label, value = "", onChange, placeholder, type = "text", defaultValue, className = "", containerClassName = "", labelTop }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef();

  const focusInput = () => {
    if (inputRef.current && !isFocused) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={classNames(
        `${containerClassName} custom-input`,
        { "custom-input--focused": isFocused }
      )}
      // onClick={focusInput}
    >
      <input
        ref={inputRef}
        id="custom-input"
        className={`${className} custom-input__input`}
        type={type}
        onChange={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {label && (
        <label onClick={focusInput} htmlFor="custom-input" className={classNames(
          "custom-input__label",
          { "custom-input__label--top": !!value || !!labelTop }
        )}>
          {label}
        </label>
      )}
    </div>
  );
};
