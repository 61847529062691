import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../../../../../../components/ui/Buttons/Button/Button";
import { ButtonLoader } from "../../../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { useDispatchedActions, useTranslation } from "../../../../../../../../hooks";
import { ApiService } from "../../../../../../../../services/ApiService";
import { errorToast } from "../../../../../../../../utils/helpers/customToast.helper";
import { CustomTextArea } from "../../../../../../../../components/ui/CustomTextArea/CustomTextArea";
import "./EditNotesModal.scss";

export const EditNotesModal = ({ setIsEditModalOpen }) => {
  const {
    buttons: { save, cancel },
    cabinet: { orders: { notes: { note, enterNote } } }
  } = useTranslation();

  const { updateDescription, setOrderData } = useDispatchedActions();
  const { orderData: order } = useSelector((state) => state.cabinet);

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue(order?.description);
  }, [order]);

  const handleClose = () => {
    setIsEditModalOpen(false);
  };

  const updateNotes = async () => {
    if (value === order.description || (!value.trim() && !order.description)) {
      setIsEditModalOpen(false);
      return;
    }

    setIsLoading(true);
    try {
      const orderData = {
        id: order.id,
        autoExtending: order.autoExtending,
        autoExtendingDays: order.autoExtendingDays,
        ipAddressFolderId: order.ipAddressFolderId,
        proxyType: order.proxyType,
        description: value || null
      };

      const { status } = await ApiService.editUserTableData(orderData);

      if (status !== 200) {
        throw Error();
      }

      setOrderData({});
      updateDescription(orderData);
      setIsEditModalOpen(false);

    } catch {
      errorToast("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="edit-notes">
      <div className="edit-notes__title">{note}</div>
      <CustomTextArea
        className="edit-notes__textarea"
        placeholder={enterNote}
        value={value}
        onChange={setValue}
      />
      <div className="edit-notes__actions ">
        <Button
          onClick={handleClose}
        >
          {cancel}
        </Button>
        <Button
          tab="confirm"
          onClick={updateNotes}
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader /> : save }
        </Button>
      </div>
    </div>
  );
};
